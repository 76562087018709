/**
 * @file It contains scripts for users overview.
 */
import { mapGetters } from "vuex"
import { getListOfStrings } from "@/utils"
import { getHeadersForTable, getFootersForTable } from "@/utils/table"
import { TABLE_NAMES } from "@/constants"

export default {
  name: "Users",
  data() {
    return {
      filters: undefined,
      search : undefined
    }
  },
  methods: {
    /**
     * This method is used custom filtering for vuetify data table.
     * This will be called for every cell value in the table.
     * @param {*} value is content of each cell in a the table
     */
    customFilterForTable(value) {
      return this.filters.findIndex(element => value && value.toString().includes(element)) + 1
    }
  },
  computed: {
    ...mapGetters({
      users              : "users/users",
      groups             : "groups/groups",
      groupsOfUsers      : "users/groupsOfUsers",
      isDefaultSsoEnabled: "configurations/isDefaultSsoEnabled",
      usersCount         : "configurations/usersCount"
    }),
    groupsMap() {
      const groupsMap = new Object()
      for (const group of this.groups) {
        groupsMap[group.id] = group
      }
      return groupsMap
    },
    usersForTable: function() {
      return this.users.map(user => {
        const userForTable = {
          id       : user.id,
          name     : user.name,
          email    : user.email,
          jobTitle : user.jobTitle,
          status   : this.$t(user.enabled ? (user.lastLogin ? "130" : "131") : "129"),
          mfaStatus: this.$t(user.mfaEnabled ? "130" : "129"),
          groups   : new Array()
        }
        if (this.groupsOfUsers[user.id]) {
          for (const group of this.groupsOfUsers[user.id]) {
            userForTable.groups.push(this.groupsMap[group]?.name)
          }
        }
        return userForTable
      })
    },
    headersForTable() {
      const excludedColumns = this.isDefaultSsoEnabled ? ["status", "mfaStatus"] : undefined

      return getHeadersForTable(
        TABLE_NAMES.USERS,
        this.$t.bind(this),
        excludedColumns
      )
    },
    footersForTable() {
      return getFootersForTable(TABLE_NAMES.USERS, this.$t.bind(this))
    },
    itemsForSearch() {
      return getListOfStrings(this.usersForTable, ["id"])
    },
    numberOfUsersWithoutSupportUser() {
      return this.users.filter(user => !user.supportUser).length
    },
    isAddUserButtonDisabled() {
      return this.numberOfUsersWithoutSupportUser >= this.usersCount
    }
  },
  watch: {
    filters: function(newFilters) {
      this.search = newFilters ? newFilters.toString() : undefined
    }
  }
}